<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  html() {
    return {
      title: 'App',
      meta: [
        { mid: 'theme-color', name: 'theme-color', content: '#3e4095', },
        { mid: 'viewport', name: 'viewport', content: 'initial-scale=1, viewport-fit=cover, user-scalable=no', },
      ],
    }
  },
  data() {
    return {
    }
  },
  computed: {
    color() {
      return $app.auth.user().company?.color || '#57419D'
    },
  },
  watch: {
    color() {
      this.setColor()
    },
  },
  mounted() {
    this.setColor()
  },
  beforeCreate() {
    $app.auth.init()
  },
  methods: {
    setColor() {
      document.body.style.setProperty('--primary', this.color)
    },
  },
}
</script>

<style lang="scss">
@import '~nast-ui/styles/global';
@import url('https://fonts.googleapis.com/css2?family=Roboto');

@include initialize((
    default: (
        colors: (
            primary: #57419D,
            secondary: #686868,
            tertiary: #3A9EAA33,
        ),
        typography: (
            header-font: 'Roboto, sans-serif',
            text-font: '400 1em Roboto, sans-serif',
            h1-font: '300 2em var(--header-font)',
            h3-font: '400 1.2em var(--header-font)',
            text-color: #444,
        ),
    ),
));


.container {
  padding: 95px 30px 0 30px;
}

.n-input {
  width: 85vw !important;

  .n-wrapper {
  }

  .n-content {
    box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px #CBCBE0 !important;
    background-color: #F5F5FA !important;
    border-radius: 16px !important;
  }
}

.button-like {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF !important;
  border-radius: 12px !important;
  box-shadow: -5px -5px 10px rgba(193, 193, 193, 0.3), 5px 5px 10px rgba(193, 193, 193, 0.3), 10px 10px 20px rgba(193, 193, 193, 0.3), -10px -10px 20px rgba(193, 193, 193, 0.3);
}

.n-button {
  &.danger {
    --danger: transparent;
    --danger-text: #FF0000;
  }
}


.n-tabs {
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #EDC9A8;
  margin-bottom: 12px;

  & > .n-tab {
    font-weight: 400;
    font-size: 12px;
    color: #000;
    border-bottom: none;
    border-width: 0 !important;
    border-right: 1px solid #EDC9A8 !important;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-right: none !important;
    }

    &.active {
      background: #EDC9A8;
      border: none;
      color: #000 !important;
    }
  }
}
</style>
